import React from 'react'
import styles from './NavBar.module.scss'
import classNames from 'classnames'
import { motion, } from 'framer-motion'
const Dots = () => {
  return (
    <motion.div
      initial={{ y: 10, opacity: 0 }} // Initial position outside the viewport (above)
      animate={{ y: 0, opacity: 1 }} // Animation to slide in from top to bottom
      exit={{ y: 10, opacity: 0 }} // Animation to slide out from bottom to top
      transition={{ duration: 0.3 }}
      className={classNames(styles['row'], styles['align-center'], styles['justify-center'], styles['dot_cont'], styles['mt-6'])}>
      <div className={classNames(styles['blue_dot'], styles['mr-6'])}></div>
      <div className={classNames(styles['blue_dot'], styles['mr-6'])}></div>
      <div className={classNames(styles['blue_dot'])}></div>
    </motion.div>
  )
}

export default Dots