import React, { useState } from 'react'
import classNames from 'classnames';
import styles from './NavBar.module.scss'
import Dots from './dots';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';

interface ItemPropType {
    title: string;
    // selected: boolean;
    // onClick: () => void;
    url: string;
}
const Item = ({ title, url }: ItemPropType) => {
    const [show, setShow] = useState(false);
    return (
        <Link className={styles['deco-none']} href={url}>
            <motion.div onHoverStart={() => { setShow(true) }} onHoverEnd={() => { setShow(false) }} className={styles['subtitle_cont']} >
                <p className={classNames(styles.exploreVenues)}>{title}</p>
                <AnimatePresence>
                    {show && <div className={styles['hidden-div']}><Dots /></div>}
                </AnimatePresence>
            </motion.div>
        </Link>
    )
}

export default Item