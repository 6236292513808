import React, { ReactNode, useMemo, useState, useEffect } from 'react'
import styles from './NavBar.module.scss';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import Logo from '@/components/common/logo/logo';
import Logo2 from '../../../../public/images/Logowhite.png';
import getturftownbutton from '../../../../public/landingimages/getturftownbutton.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
    isAndroid,
    isIOS
} from "react-device-detect";
import Item from './item';
import GetTurfTownModal from '../GetTurfTownModal';
import Link from 'next/link';

export interface NavbarPropType {
    minimal?: boolean;
    leftComponent?: ReactNode;
    noAnimtaion?: boolean;
}

const NavBar = ({ minimal = false, leftComponent, noAnimtaion }: NavbarPropType) => {
    const router = useRouter();

    const [showModal, setModal] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 500) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const openMenu = () => {
        setShowMenu(true);
    };

    const closeMenu = () => {
        setTimeout(() => {
            setShowMenu(false);
        }, 50);
    };

    const goToExploreVenue = () => {
        router.push('/sports-venues');
        setShowMenu(false);
    }
    const goToExploreGames = () => {
        router.push('/games');
        setShowMenu(false);
    }
    const goToHome = () => {
        router.push('/');
        setShowMenu(false);
    }

    const handleDownload = () => {
        setShowMenu(false);

        if (isAndroid) {
            window.location.href =
                "https://play.google.com/store/apps/details?id=com.turftown&hl=en_US";
        } else if (isIOS) {
            window.location.href =
                "https://onelink.to/turftown";
        } else {
            // setModal(true)
            window.location.href =
                "https://turftown.in";
        }
    }

    const homePageRoute = () => {
        router.push('/')
    }

    const exploreGamesRoute = () => {
        if (router.pathname == '/games') router.reload();
        router.push('/games');
    }

    return (
        <>
            <motion.div animate={{ y: 0, opacity: 1 }} initial={noAnimtaion ? { y: 0, opacity: 0 } : { y: -70, opacity: 0 }}
                transition={{ type: 'spring', damping: 34, stiffness: 230, mass: 2 }}>
                <nav className={classNames(styles.nav_desktop_container)}>
                    <Image src={Logo2} alt='desktoplogo' className={classNames(styles.nav_desktop_logo)} onClick={homePageRoute} />
                    <div className={styles.navbar_subDirectory}>

                        {!minimal && <div className={styles.subTitles}>
                            <Item title={'Games'} url={'/games'} />
                            <Item title={'Venues'} url={'/sports-venues'} />
                        </div>}


                        <Image src={getturftownbutton} alt='get-turf-town-button' className={classNames(styles.getTurf_desktop_button)} onClick={() => setModal(true)} />
                    </div>
                </nav>
            </motion.div>

            <motion.div
                className={classNames(styles["navbar_container"], isScrolled && styles['fixed'])}>
                {leftComponent ? leftComponent : <Logo />}
                <Image src={'/icons/menu_icon.png'} alt='menuIcon' height={20} width={22}
                    className={classNames(styles["cursor-pointer"], styles.menuIcon_bar)} onClick={openMenu} />
            </motion.div>

            {showMenu && (
                <motion.div
                    className={styles.menuModal}>
                    <div className={styles.menuBar_right}>
                        <Logo />
                        <Image src={'/landingimages/close_icon.png'} alt="close" onClick={closeMenu}
                            height={18} width={18} />
                    </div>
                    <div className={styles.menuBar_left}>

                        <div className={styles.menuList}>
                            {!minimal && (<>
                                <div onClick={handleDownload} className={classNames(styles['row'], styles['align-center'], styles['mt-8'])}>
                                    <p className={classNames(styles['mr-8'], styles['nave_text'])}>Get Turf Town</p>
                                    <Image src={'/images/arrow.png'} alt='get turftown'
                                        height={14} width={18} onClick={handleDownload} style={{ marginTop: 1 }} />
                                </div>

                                <Link href={'/'} className={classNames(styles['mt-32'], styles['deco-none'], styles['nave_text'])} onClick={goToHome}>Home</Link>
                                <Link href={'/sports-venues'} className={classNames(styles['mt-32'], styles['deco-none'], styles['nave_text'])} onClick={goToExploreVenue} >Venues</Link>
                                <Link href={'/games'} className={classNames(styles['mt-32'], styles['deco-none'], styles['nave_text'])} onClick={goToExploreVenue} >Games</Link>
                                {/* <div className={styles['mt-32']} onClick={goToExploreGames}>Games</div> */}
                            </>)}
                        </div>
                    </div>

                </motion.div>
            )}

            <GetTurfTownModal show={showModal} onClose={() => {
                setModal(false)
            }} />
        </>
    )
}

export default NavBar